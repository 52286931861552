.aboutme {
    font-size: 2rem;
    font-weight: 800;
    text-align: center;
    font-family: "Lilita One", sans-serif;
}

.aboutbox {
    background: transparent;
    border-radius: 15px;
}

.aboutbox p {
    font-family: "Playfair Display", serif;
   font-size: 18px;
   font-weight: 400;
   color: rgb(2, 2, 2);
}

.aboutbox h2 {
    font-family: "Abril Fatface", serif;
}

a {
    text-decoration: none;
    color: white;
    font-family: "Roboto", sans-serif;
    padding: 4px;
}
