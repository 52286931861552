.box {
   background: linear-gradient(140deg, rgb(250, 211, 255), rgb(62, 33, 70)); 
    box-shadow: 0 5px 6px 2px rgb(206, 206, 206);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.stack p {
    color: rgb(255, 255, 255);
    font-size: 16px;
}

.stack h1 {
    color: rgb(255, 255, 255);
 
}
