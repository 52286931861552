body {
  margin: 0;
  width: '100%';
  font-family: "Poppins", Sans-serif;
}


html {
  scroll-behavior: smooth;

}

*, *:before, *:after {
  box-sizing: border-box;
}

