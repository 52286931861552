.header h1{
    font-size: 2rem;
    margin: 1em;
    font-weight: 800;
    text-align: center;
    font-family: "Abril Fatface", serif;
    color: #463b8a;
}

.header h2 {
    font-weight: 800;
    text-align: center;
    font-family: "Abril Fatface", serif;
    color: white;
    text-shadow: 0 0 2px rgb(65, 65, 65);
    margin: 0;
}

.card {
    background: linear-gradient(140deg, rgb(247, 229, 255), rgb(37, 8, 44)); 
    box-shadow: 0 5px 6px 2px rgb(202, 202, 202);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    border-radius: 2px;
}

.card p {
    color: white;;
    font-size: 16px;
    text-shadow: 0 0 5px black;
}

.download {
    text-decoration: none;
    color: white;
    font-family: "Roboto", sans-serif;
}