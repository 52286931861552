.list {
    display:flex;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
    list-style:none;
}

.list li {
    position: relative;
    width: 100%;
    font-size:11px;
    color: rgb(82, 82, 82) !important;
}


.list li::after {
    position: absolute;
    content: '';
    height: 1px;
    width: 0;
    bottom: -3px;
    left: 0;
    background: rgb(117, 117, 117);
    transition: 0.4s;
}

.list li:hover::after {
    width: 100%;
}



.list a {
    text-decoration: none;
}



.listmobile {
    padding:0 4rem;
    list-style:none;
    font-size:1rem;
}

.listmobile > a {
    text-decoration: none;
    margin: 1em;
    font-weight: 200;

    font-family: "Poppins", Sans-serif
}

.listmobile li {
    padding: 0rem;
}

.navbar {
    top: 0;
    z-index: 1;
    transition: top 1s;
    box-sizing: border-box;
  }


.shadow {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    box-shadow: none;
}

.color {
    color:rgb(9, 21, 44);
}


