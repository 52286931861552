.box {
    background: white;
    filter: drop-shadow(0 0 12px rgb(221, 221, 221));
    padding: 1rem;
    border-radius: 1rem;
    border-style: solid;
    border-color: rgb(207, 207, 207);
    border-width: 1px;
    box-shadow: 0 0 15px 2px rgb(141, 141, 141);
}

.boximg {
    position: relative;
    border-radius: 0.5rem;
    box-shadow:0 0 8px rgb(194, 194, 194);
}

.modalheader {
    font-size: 2rem;
    background: -webkit-linear-gradient(300deg,rgb(95, 101, 113),rgb(0, 0, 1));
    background-clip:text;
    -webkit-background-clip:text;
    -webkit-text-fill-color: transparent;
}

.text {
    background: -webkit-linear-gradient(300deg,rgb(77, 82, 90),rgb(67, 67, 69));
    background-clip:text;
    -webkit-background-clip:text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    padding: 5px;
}


